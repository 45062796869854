import { Hero } from "../../features/hero/components/Hero";
import { useArtists } from "../../hooks/useArtists";
import heroImage from "./assets/images/hero1.jpg";
import { Artists } from "./components/Artists";

export const Home = () => {
  const { artists, error, isLoading } = useArtists();

  return (
    <div>
      <Hero
        image={heroImage}
        heading="NEST OF THORNS"
        subHeading="Bright, airy and inclusive tattoo studio located in Glasgow city centre, offering high end, custom tattooing"
      />

      <div className="container flex flex-col mx-auto text-center gap-6 p-6">
        <h1 className="font-light" style={{ fontSize: 30 }}>
          ABOUT US
        </h1>
        <p className="font-light md:text-xl text-lg">
          Nest of Thorns is a private tattoo studio located on Bath street in
          the heart of Glasgow city centre.
        </p>
        <p className="font-light  md:text-xl text-lg">
          A bright and inviting open studio workspace, filled with art, antiques
          and curiosities We offer professional custom tattooing in a relaxed
          and welcoming setting, just a short walk from both Glasgow queen
          street and central train stations.
        </p>
        <p className="font-light  md:text-xl text-lg">
          Our team of artists cater to all styles of tattooing, from traditional
          to realism, blackwork to japanese - just have a browse through our
          artist portfolios to find the right artist for you.
        </p>
      </div>

      <div className="container flex flex-col mx-auto text-center gap-6 p-6">
        <h1 className="font-light" style={{ fontSize: 30 }}>
          OUR ARTISTS
        </h1>

        {isLoading && <p>Loading...</p>}
        {artists && <Artists artists={artists} />}
        {error && (
          <p>An error occured. Please reload the page or come back later.</p>
        )}
      </div>
    </div>
  );
};
