export const Hero = ({ image, heading, subHeading }) => {
  return (
    <div className="hero">
      <div
        className="container mx-auto p-4 absolute text-white"
        style={{
          left: 0,
          right: 0,
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <div
          className="flex flex-col text-center gap-6 rounded w-fit mx-auto p-4"
          style={{ background: "rgba(0,0,0,0.5)" }}
        >
          <h1 className="font-light text-5xl">{heading}</h1>
          <p className="text-xl font-light">{subHeading}</p>
        </div>
      </div>
      <img src={image} className="h-full w-full heroImg" alt="hero" />
    </div>
  );
};
