import { Card, CardContent, IconButton, Tooltip } from "@mui/material";
import { UserEntry } from "../../atoms/user-entry/UserEntry";
import { useUsers } from "../../../hooks/useUsers";
import { PersonAdd } from "@mui/icons-material";

export const UserContainer = () => {
  const { users, isLoading, error } = useUsers();

  if (isLoading) return "...loading";
  if (error) return "Something happened, try again";

  if (!users) return null;

  return (
    <div>
      <div className="text-right">
        <Tooltip title="Add user">
          <IconButton>
            <PersonAdd />
          </IconButton>
        </Tooltip>
      </div>
      {users.map((user) => {
        if (user.Id !== localStorage.getItem("loginId"))
          return (
            <Card key={user.Id} className="md:w-fit">
              <CardContent>
                <div className="flex flex-col">
                  <UserEntry user={user} />
                </div>
              </CardContent>
            </Card>
          );
      })}
    </div>
  );
};
