import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Home,
  Artists,
  ConsentForm,
  Contact,
  Booking,
  Login,
  Dashboard,
  ProtectedRoute,
  Error,
} from "../pages";
import { checkUserLoggedIn } from "../utils/user";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/artists" element={<Artists />} />
        <Route path="/consent-form" element={<ConsentForm />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/bookings" element={<Booking />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute
              outlet={<Dashboard />}
              shouldRenderOutlet={checkUserLoggedIn()}
              redirectToPath="/error"
            />
          }
        />
        <Route path="/error" element={<Error />} />
      </Routes>
    </Router>
  );
}

export default App;
