import { useQuery } from "@tanstack/react-query";
import React from "react";
import { API_PATHS, BASE_URL } from "../config/constants";

export const useUsers = () => {
  const [users, setUsers] = React.useState(null);

  const { isLoading, error, data, isSuccess } = useQuery({
    queryKey: ["users"],
    queryFn: () =>
      fetch(`${BASE_URL}${API_PATHS.USERS}`).then((res) => res.json()),
    refetchOnWindowFocus: true,
  });

  React.useEffect(() => {
    if (isSuccess && data.users) setUsers(data.users);
  }, [data]);

  return { users, error, isLoading };
};
