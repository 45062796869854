import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import {
  IconButton,
  Button,
  AppBar,
  Toolbar,
  Drawer,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";
import logo from "../../../assets/images/logo.png";
import { PATHS } from "../../../config/constants";
import { checkUserLoggedIn, logout } from "../../../utils/user";
import { AccountCircle, ExitToApp } from "@mui/icons-material";
import { useMenu } from "../../../hooks/useMenu";

export const Header = () => {
  const [drawerOpen, toggleDrawer] = React.useState(false);
  const userLoggedIn = checkUserLoggedIn();
  const { menuAnchorEl, handleMenuClick, handleMenuClose, menuOpen } =
    useMenu();

  return (
    <div className="background-dark sticky top-0 z-50">
      <AppBar position="sticky" sx={{ background: "#2c2c2c" }}>
        <div className="container mx-auto">
          <Toolbar>
            <div className="grid grid-cols-12 lg:hidden items-center w-full">
              <div className="col-span-4">
                <IconButton onClick={() => toggleDrawer(!drawerOpen)}>
                  <MenuIcon className="text-light" />
                </IconButton>
              </div>
              <div className="col-span-4 mx-auto">
                <div className="h-20 col-span-4 mx-auto flex items-center">
                  <img src={logo} alt="nest of thorns" className="h-16 w-16" />
                </div>
              </div>
              <div className="col-span-4 ml-auto">
                {userLoggedIn && (
                  <div>
                    <IconButton onClick={handleMenuClick}>
                      <AccountCircle className="text-light" />
                    </IconButton>
                    <Menu
                      id="account-menu"
                      anchorEl={menuAnchorEl}
                      open={menuOpen}
                      onClose={handleMenuClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleMenuClose();
                          logout();
                        }}
                      >
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                )}
              </div>
            </div>

            <div className="hidden lg:grid grid-cols-12 items-center w-full justify-between">
              <div className="col-span-4">
                <IconButton onClick={() => (window.location.href = PATHS.HOME)}>
                  <HomeIcon className="text-light" />
                </IconButton>
                <Button
                  onClick={() => (window.location.href = PATHS.ARTISTS)}
                  className="text-light"
                >
                  <p>ARTISTS</p>
                </Button>
                <Button
                  onClick={() => (window.location.href = PATHS.CONTACT)}
                  className="text-light"
                >
                  <p>CONTACT US</p>
                </Button>
              </div>
              <div className="h-20 col-span-4 mx-auto flex items-center">
                <img src={logo} alt="nest of thorns" className="h-16 w-16" />
              </div>
              <div className="col-span-4 text-right">
                <Button
                  onClick={() => (window.location.href = PATHS.BOOKINGS)}
                  className="text-light"
                >
                  <p>BOOKINGS</p>
                </Button>
                {userLoggedIn ? (
                  <>
                    <Button
                      onClick={() => (window.location.href = PATHS.DASHBOARD)}
                      className="text-light"
                    >
                      <p>DASHBOARD</p>
                    </Button>
                    <Tooltip title={"Logout"}>
                      <IconButton onClick={() => logout()}>
                        <ExitToApp className="text-light" />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <Button
                    onClick={() => (window.location.href = PATHS.LOGIN)}
                    className="text-light"
                  >
                    <p>LOGIN</p>
                  </Button>
                )}
              </div>
            </div>
          </Toolbar>
          <Drawer
            anchor="top"
            open={drawerOpen}
            onClose={() => toggleDrawer(false)}
          >
            <div className="background-dark flex flex-col gap-2">
              <IconButton onClick={() => (window.location.href = PATHS.HOME)}>
                <HomeIcon className="text-light" />
              </IconButton>
              <Button
                className="text-light"
                onClick={() => (window.location.href = PATHS.ARTISTS)}
              >
                <p>Artists</p>
              </Button>
              <Button
                className="text-light"
                onClick={() => (window.location.href = PATHS.CONTACT)}
              >
                <p>Contact us</p>
              </Button>
              <Button
                className="text-light"
                onClick={() => (window.location.href = PATHS.BOOKINGS)}
              >
                <p>Bookings</p>
              </Button>

              {userLoggedIn ? (
                <Button
                  className="text-light"
                  onClick={() => (window.location.href = PATHS.DASHBOARD)}
                >
                  <p>Dashboard</p>
                </Button>
              ) : (
                <Button
                  className="text-light"
                  onClick={() => (window.location.href = PATHS.LOGIN)}
                >
                  <p>Login</p>
                </Button>
              )}
            </div>
          </Drawer>
        </div>
      </AppBar>
    </div>
  );
};
