export const checkUserLoggedIn = () => {
  return localStorage.getItem("loginId") ? true : false;
};

export const logout = () => {
  localStorage.removeItem("loginId");
  window.location.href = "/login";
};

export const getLoggedInUser = (users) => {
  const loggedInUserId = localStorage.getItem("loginId");

  return users.find((user) => loggedInUserId === user.Id);
};
