export const Error = () => {
  return (
    <div className="container flex flex-col mx-auto text-center gap-6 p-4">
      <div className="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0 mx-auto">
        <img
          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
          className="w-full"
          alt="logo"
        />
      </div>
      <h1 className="font-light" style={{ fontSize: 25 }}>
        Oops something went wrong...
      </h1>
      <p>
        You may not have permission to view this page. If you think this is an
        error please try and log in.
      </p>
    </div>
  );
};
