import {
  Container,
  FormControl,
  TextField,
  Typography,
  Button,
  Alert,
} from "@mui/material";
import useForm from "../../hooks/useForm";
import { useContact } from "./useContact";

export const Contact = () => {
  const { onSubmit, isSuccess, isLoading } = useContact();
  const [formData, handleChange, handleSubmit] = useForm(onSubmit);

  return (
    <form onSubmit={handleSubmit}>
      <Container maxWidth="sm" className="mx-auto my-4">
        <div className="flex flex-col mx-auto text-center gap-6 py-4">
          <Typography variant="h4">Contact us</Typography>
          <Typography variant="body1">
            Please give us some extra details to help you with your inquiry
          </Typography>

          <div className="grid grid-cols-12 gap-2">
            <TextField
              className="col-span-6"
              id="firstName"
              name="firstName"
              label="First Name"
              variant="outlined"
              onChange={handleChange}
              required={true}
            />
            <TextField
              className="col-span-6"
              id="lastName"
              name="lastName"
              label="Last Name"
              variant="outlined"
              onChange={handleChange}
              required={true}
            />
          </div>
          <FormControl>
            <TextField
              id="emailAddress"
              name="emailAddress"
              label="Email Address"
              type="email"
              variant="outlined"
              onChange={handleChange}
              required={true}
            />
            <Typography
              variant="caption"
              style={{ textAlign: "left", fontStyle: "italic" }}
            >
              This is only used to follow up your enquiry
            </Typography>
          </FormControl>
          <FormControl>
            <TextField
              id="notes"
              name="notes"
              label="Why are you getting in touch?"
              multiline={true}
              minRows="5"
              variant="outlined"
              onChange={handleChange}
              required={true}
            />
          </FormControl>
          <FormControl>
            {isSuccess && (
              <Alert severity="success">
                Contact request sent successfully
              </Alert>
            )}
          </FormControl>
          <FormControl>
            <Button
              disabled={isLoading || isSuccess}
              type="submit"
              variant="contained"
            >
              Send
            </Button>
          </FormControl>
        </div>
      </Container>
    </form>
  );
};
