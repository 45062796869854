import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import React from "react";
import firebaseConfig from "../../firebaseConfig";
import useAxios from "../../hooks/useAxios";
import axios from "../../services/Login";

export const useLogin = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app(); // if already initialized, use that one
  }
  const firebaseAppAuth = firebase.auth();

  const [response, error, loading, axiosFetch] = useAxios();

  const loginWithEmailAndPassword = ({ emailAddress, password }) => {
    firebaseAppAuth
      .signInWithEmailAndPassword(emailAddress, password)
      .then((result) => {
        completeSignIn(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loginWithGmail = () => {
    var provider = new firebase.auth.GoogleAuthProvider()
      .addScope("https://www.googleapis.com/auth/userinfo.email")
      .addScope("https://www.googleapis.com/auth/userinfo.profile");

    firebaseAppAuth
      .signInWithPopup(provider)
      .then((result) => {
        completeSignIn(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const completeSignIn = (result) => {
    const email = result.additionalUserInfo.profile.email;
    const id = result.additionalUserInfo.profile.id;
    axiosFetch({
      axiosInstance: axios,
      method: "POST",
      url: "/login",
      requestConfig: {
        email,
        id,
      },
    });
  };

  React.useEffect(() => {
    if (response.success) {
      localStorage.setItem("loginId", response.loginId);
      window.location.href = "/dashboard";
    }

    if (localStorage.getItem("loginId")) window.location.href = "/dashboard";
  }, [response]);

  return { loginWithEmailAndPassword, loginWithGmail };
};
