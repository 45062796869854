import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getLoggedInUser } from "../../utils/user";

export const useDashboard = () => {
  const { data, isSuccess } = useQuery({ queryKey: ["users"] });
  const [loggedInUser, setLoggedInUser] = React.useState(null);

  React.useEffect(() => {
    isSuccess && setLoggedInUser(getLoggedInUser(data.users));
  }, [data]);

  return { loggedInUser };
};
