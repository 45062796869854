import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({
  shouldRenderOutlet,
  outlet,
  redirectToPath,
}) => {
  if (shouldRenderOutlet) return outlet;
  return <Navigate to={{ pathname: redirectToPath }} />;
};
