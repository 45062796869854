import React from "react";

export const useMenu = () => {
  const [menuAnchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return { menuAnchorEl, handleMenuClick, handleMenuClose, menuOpen };
};
