import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import useAxios from "../../../hooks/useAxios";
import axios from "../../../services/UpdateArtist";

export const ArtistEntry = ({ artist }) => {
  const [name, setName] = useState(artist.Name);
  const [email, setEmail] = useState(artist.Email);
  const [diary, setDiary] = useState(artist.DiaryClosed);
  const [specialties, setSpecialties] = useState(artist.Specialties);
  const [blurb, setBlurb] = useState(artist.Blurb);
  const [instagramUsername, setInstagramUsername] = useState(
    artist.InstagramUsername
  );
  const [artistDisabled, setArtistDisabled] = useState(artist.Disabled);

  const [response, error, loading, axiosFetch] = useAxios();

  const updateArtist = (data) => {
    axiosFetch({
      axiosInstance: axios,
      method: "POST",
      url: "/UpdateArtist",
      requestConfig: data,
    });
  };

  const handleArtistUpdateClick = () => {
    const newArtistDetails = {
      Name: name,
      Email: email,
      DiaryClosed: diary,
      Specialties: specialties,
      Blurb: blurb,
      InstagramUsername: instagramUsername,
      Disabled: artistDisabled,
      Id: artist.Id,
    };
    updateArtist(newArtistDetails);
  };

  return (
    <Grid container spacing={2}>
      <div className="mx-auto text-slate-500 mt-2">
        <Typography variant="h6">{artist.Name}'s Profile</Typography>
      </div>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            size="small"
            value={name}
            className="text-slate-500"
            label="Name"
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          {" "}
          <TextField
            size="small"
            value={email}
            className="text-slate-500"
            label="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          {" "}
          <FormControlLabel
            control={
              <Switch checked={diary} onChange={() => setDiary(!diary)} />
            }
            label={`Diary ${diary ? "Closed" : "Open"}`}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          {" "}
          <TextField
            size="small"
            value={specialties}
            className="text-slate-500"
            label="Specialties"
            onChange={(e) => setSpecialties(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          {" "}
          <TextField
            multiline
            value={blurb}
            className="text-slate-500"
            label="Blurb"
            minRows="4"
            onChange={(e) => setBlurb(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          {" "}
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">@</InputAdornment>
              ),
            }}
            size="small"
            value={instagramUsername}
            className="text-slate-500"
            label="Instagram Username"
            onChange={(e) => setInstagramUsername(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <p>
            Only set this to disabled if you no longer want the artist to be
            visible on the site
          </p>{" "}
          <FormControlLabel
            control={
              <Switch
                checked={artistDisabled}
                onChange={() => setArtistDisabled(!artistDisabled)}
              />
            }
            label={`Artist ${artistDisabled ? "Disabled" : "Active"}`}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Tooltip title="Update artist">
          <Button onClick={handleArtistUpdateClick}>Update artist</Button>
        </Tooltip>
        {loading && <Alert severity="info">Updating Artist...</Alert>}
        {response.success && (
          <Alert severity="success">Artist updated successfully!</Alert>
        )}
        {error && (
          <Alert severity="error">
            Unable to update artist, please get in touch with IT or try again.
          </Alert>
        )}
      </Grid>
    </Grid>
  );
};
