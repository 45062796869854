import { Avatar, Typography } from "@mui/material";

export const Artist = ({ Name, Blurb }) => {
  const image = require(`../../../assets/images/${Name.toLowerCase()}_profile_pic.jpg`);
  return (
    <div className="flex flex-col">
      <div className="mx-auto mb-2">
        <Avatar src={image} alt="artist" sx={{ width: 200, height: 200 }} />
      </div>
      <div>
        <Typography variant="h5">{Name}</Typography>
      </div>
      <div>
        <Typography variant="body2">{Blurb}</Typography>
      </div>
    </div>
  );
};
