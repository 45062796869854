import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import useAxios from "../../../hooks/useAxios";
import axios from "../../../services/UpdateArtist";

export const AddArtistEntry = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [diary, setDiary] = useState(false);
  const [specialties, setSpecialties] = useState();
  const [blurb, setBlurb] = useState();
  const [instagramUsername, setInstagramUsername] = useState();
  const [artistDisabled, setArtistDisabled] = useState(false);

  const [response, error, loading, axiosFetch] = useAxios();

  const [addArtistError, setAddArtistError] = useState(false);

  const addArtist = (data) => {
    axiosFetch({
      axiosInstance: axios,
      method: "POST",
      url: "/AddArtist",
      requestConfig: data,
    });
  };

  const handleAddArtistClick = () => {
    setAddArtistError(false);
    if (!name || !email || !specialties || !blurb || !instagramUsername) {
      setAddArtistError("Please fill out all of the required fields");
      return;
    }
    const newArtistDetails = {
      Name: name,
      Email: email,
      DiaryClosed: diary,
      Specialties: specialties,
      Blurb: blurb,
      InstagramUsername: instagramUsername,
      Disabled: artistDisabled,
    };
    addArtist(newArtistDetails);
  };

  const clearEntry = () => {
    setName("");
    setEmail("");
    setDiary(false);
    setSpecialties("");
    setBlurb("");
    setInstagramUsername("");
    setArtistDisabled(false);
  };

  React.useEffect(() => {
    if (response.success) {
      clearEntry();
    }
  }, [response]);

  return (
    <Grid container spacing={2}>
      <div className="mx-auto text-slate-500 mt-2">
        <Typography variant="h6">Add Artist</Typography>
      </div>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            size="small"
            value={name}
            className="text-slate-500"
            label="Name"
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          {" "}
          <TextField
            size="small"
            value={email}
            className="text-slate-500"
            label="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          {" "}
          <FormControlLabel
            control={
              <Switch checked={diary} onChange={() => setDiary(!diary)} />
            }
            label={`Diary ${diary ? "Closed" : "Open"}`}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          {" "}
          <TextField
            size="small"
            value={specialties}
            className="text-slate-500"
            label="Specialties"
            onChange={(e) => setSpecialties(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          {" "}
          <TextField
            multiline
            value={blurb}
            className="text-slate-500"
            label="Blurb"
            minRows="4"
            onChange={(e) => setBlurb(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          {" "}
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">@</InputAdornment>
              ),
            }}
            size="small"
            value={instagramUsername}
            className="text-slate-500"
            label="Instagram Username"
            onChange={(e) => setInstagramUsername(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <p>
            Only set this to disabled if you no longer want the artist to be
            visible on the site
          </p>{" "}
          <FormControlLabel
            control={
              <Switch
                checked={artistDisabled}
                onChange={() => setArtistDisabled(!artistDisabled)}
              />
            }
            label={`Artist ${artistDisabled ? "Disabled" : "Active"}`}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Tooltip title="Update artist">
          <Button onClick={handleAddArtistClick}>Add artist</Button>
        </Tooltip>
        {loading && <Alert severity="info">Adding Artist...</Alert>}
        {response.success && (
          <Alert severity="success">Artist added successfully!</Alert>
        )}
        {error && (
          <Alert severity="error">
            Unable to add artist, please get in touch with IT or try again.
          </Alert>
        )}
        {response.success === false && response.message && (
          <Alert severity="info">{response.message}</Alert>
        )}
        {addArtistError && <Alert severity="info">{addArtistError}</Alert>}
      </Grid>
    </Grid>
  );
};
