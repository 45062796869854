import React from "react";
import { Button, Divider, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";

export const ArtistCard = ({ artist }) => {
  const image = require(`../../../assets/images/${artist.Name.toLowerCase()}_profile_pic.jpg`);

  return (
    <div
      className={`flex flex-col bg-gray-100 md:p-6 p-2 rounded-xl gap-4 mx-2 md:mx-0`}
    >
      <div className="flex flex-col sm:flex-row items-center">
        <img
          src={image}
          alt="artist"
          style={{ height: 100, width: 100, borderRadius: "50%" }}
        />
        <div className="flex flex-col text-left ml-6 text-center md:text-left">
          <div className="flex flex-row items-center gap-2">
            <Typography variant="h5">{artist.Name}</Typography>
            <a
              target={"_blank"}
              href={`https://www.instagram.com/${artist.InstagramUsername}`}
            >
              <InstagramIcon className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-lg text-white" />
            </a>
            <Typography variant="caption">
              @{artist.InstagramUsername}
            </Typography>
          </div>

          <Typography variant="body2">{artist.Specialties}</Typography>
        </div>
      </div>
      <Divider />
      <div className="md:text-left text-center">
        <Typography variant="body2">{artist.Blurb}</Typography>
      </div>
      <div>
        {artist.DiaryClosed ? (
          <Typography variant="body2" className="text-slate-500">
            <i>Diary closed</i>
          </Typography>
        ) : (
          <Button
            target={"_blank"}
            href={`https://www.instagram.com/${artist.InstagramUsername}`}
          >
            Check out my portfolio
          </Button>
        )}
      </div>
    </div>
  );
};
