import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_PATHS, BASE_URL } from "../../config/constants";

export const useBooking = () => {
  const sendBookingRequest = async (data) => {
    const { data: response } = await axios.post(
      BASE_URL + API_PATHS.BOOKING_REQUEST,
      data
    );
    return response.data;
  };

  const { mutate, isSuccess } = useMutation(sendBookingRequest);

  const onSubmit = (state) => {
    mutate(state);
  };

  return { onSubmit, isSuccess };
};
