import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import { Typography } from "@mui/material";

export const GoogleMap = () => {
  const defaultProps = {
    center: {
      lat: 55.86534153768105,
      lng: -4.26307133068062,
    },
    zoom: 16,
  };
  // return (
  //   // <div style={{ height: "400px", width: "100%" }}>
  //   //   {/* <div className="container mx-auto text-center mb-4">
  //   //     <p className="font-light text-4xl ">Find us here!</p>
  //   //   </div>
  //   //   <GoogleMapReact
  //   //     bootstrapURLKeys={{ key: "AIzaSyAgP_RLbfBRCnLZgeP75RI8zab_qWaAgX4" }}
  //   //     defaultCenter={defaultProps.center}
  //   //     defaultZoom={defaultProps.zoom}
  //   //   >
  //   //     <RoomIcon
  //   //       lat={defaultProps.center.lat}
  //   //       lng={defaultProps.center.lng}
  //   //       text="We are here!"
  //   //     />
  //   //   </GoogleMapReact> */}
  //   // </div>
  // );
  return null;
};
