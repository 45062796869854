import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ArtistContainer } from "../../components/molecules/artist-container/ArtistContainer";
import { UserContainer } from "../../components/molecules/user-container/UserContainer";
import { useTabs } from "../../hooks/useTabs";
import { checkUserLoggedIn } from "../../utils/user";
import { useDashboard } from "./useDashboard";

export const Dashboard = () => {
  const { loggedInUser } = useDashboard();
  const { TabPanel, a11yProps, handleTabsChange, tabsValue } = useTabs();

  return (
    <div className="bg-slate-100">
      <div className="container flex flex-col mx-auto text-center gap-x-4 gap-y-2 p-6">
        <p>
          Welcome {loggedInUser ? loggedInUser.Name.split(" ")[0] + "!" : null}
        </p>

        <Tabs value={tabsValue} onChange={handleTabsChange}>
          <Tab label="Users" {...a11yProps(0)} />
          <Tab label="Artists" {...a11yProps(1)} />
        </Tabs>

        <TabPanel value={tabsValue} index={0}>
          <UserContainer />
        </TabPanel>
        <TabPanel value={tabsValue} index={1}>
          <ArtistContainer />
        </TabPanel>
      </div>
    </div>
  );
};
