import React from "react";
import {
  Container,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Alert,
} from "@mui/material";
import { Hero } from "../../features/hero/components/Hero";
import useForm from "../../hooks/useForm";
import heroImage from "./assets/images/hero1.jpg";
import useAxios from "../../hooks/useAxios";
import axios from "../../services/UploadConsentForm";
import { useArtists } from "../../hooks/useArtists";

export const ConsentForm = () => {
  const [response, error, loading, axiosFetch] = useAxios();
  const { artists } = useArtists();

  const onSubmit = (data) => {
    axiosFetch({
      axiosInstance: axios,
      method: "POST",
      url: "/AddConsentForm",
      requestConfig: data,
    });
  };

  const [formData, handleChange, handleSubmit] = useForm(onSubmit);

  return (
    <div>
      <Hero
        image={heroImage}
        heading="Consent Form"
        subHeading="Please fill complete the form below to complete your consent form"
      />
      {artists && (
        <form onSubmit={handleSubmit}>
          <Container maxWidth="sm" className="mx-auto my-4">
            <div className="flex flex-col mx-auto text-center gap-6 py-4">
              <div className="grid grid-cols-11 gap-2">
                <TextField
                  className="col-span-3"
                  id="title"
                  name="title"
                  label="Title"
                  variant="outlined"
                  onChange={handleChange}
                  required={true}
                />
                <TextField
                  className="col-span-4"
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                  onChange={handleChange}
                  required={true}
                />
                <TextField
                  className="col-span-4"
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  onChange={handleChange}
                  required={true}
                />
              </div>
              <FormControl>
                <InputLabel id="artist">
                  Select the artist you are booked with
                </InputLabel>
                <Select
                  value={formData.artist || ""}
                  id="artist"
                  label="Which artist will be tattoing you?"
                  name="artist"
                  onChange={handleChange}
                  placeholder="Select an artist"
                  required={true}
                  className="text-left"
                >
                  {artists.map((artist) => {
                    return (
                      <MenuItem
                        key={`artistDropdownOption-${artist.Id}`}
                        value={artist.Id}
                      >
                        {artist.Name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Typography variant="body1" className="font-bold">
                Please read the following carefully and complete any questions
                before submitting...
              </Typography>

              <Typography variant="body2" className="font-light">
                I,{" "}
                {formData.firstName && formData.lastName
                  ? formData.firstName + " " + formData.lastName
                  : "(Name of Patron)"}{" "}
                hereby give consent to{" "}
                {formData.artist ? formData.artist : "(Name of Operator)"} &amp;
                Nest of Thorns to perform a tattoo/body pierce, and in
                consideration of doing so, I hereby release{" "}
                {formData.artist ? formData.artist : "(Name of Operator)"} and
                Nest of Thorns from all manner of liabilities, claims, actions,
                and demands in law, or in equity, which I or my heirs might now
                or hereafter by reason of complying with my request of a tattoo
                or body piercing.
              </Typography>
              <Typography variant="body2" className="font-light">
                I fully understand that any employee of Nest of Thorns, when
                performing a tattoo or body piercing, does not act in the
                capacity as a medical professional. The suggestions made by any
                employee or agent of Nest of Thorns are just suggestions. They
                are not to be construed as, or substituted for advice from a
                medical professional. I understand that the tattoo or body
                piercing will be performed using appropriate techniques,
                instruments and pigments. I also understand that infections can
                occur due to lack of proper hygene and/or pigment sensitivities.
                To ensure proper healing of my tattoo or body piercing, I agree
                to follow the written and verbal aftercare instructions that
                will be provided, until healing is complete. I understand that a
                tattoo or body piercing may take several weeks to heal properly.
              </Typography>

              <Typography variant="body2" className="font-light">
                I understand that I am making a permanent change to my body and
                no claims about the possibility of reversing these changes have
                been made or implied by Nest of Thorns or any of it's employees
                or agents.
              </Typography>

              <Typography variant="body1" className="font-bold">
                Please answer the following questions: Answering "Yes" to any of
                these questions does not neccessarily preclude the person from
                receiving a tattoo or piercing.
              </Typography>

              <FormControl className="text-left">
                <FormLabel id="hepatitusCheck">
                  Has a physician told you that you have hepatitis?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="hepatitusCheck"
                  name="hepatitusCheck"
                  value={formData.hepatitusCheck || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <FormControl className="text-left">
                <FormLabel id="jaundiceCheck">
                  Have you been jaundice (yellowing of skin or eyes) in the
                  previous 10 days?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="jaundiceCheck"
                  name="jaundiceCheck"
                  value={formData.jaundiceCheck || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <FormControl className="text-left">
                <FormLabel id="faintingCheck">
                  Are you prone to fainting?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="faintingCheck"
                  name="faintingCheck"
                  value={formData.faintingCheck || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <FormControl className="text-left">
                <FormLabel id="diabetesCheck">Do you have diabetes?</FormLabel>
                <RadioGroup
                  aria-labelledby="diabetesCheck"
                  name="diabetesCheck"
                  value={formData.diabetesCheck || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <FormControl className="text-left">
                <FormLabel id="bleedingCheck">
                  Do you have difficulty stopping bleeding?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="bleedingCheck"
                  name="bleedingCheck"
                  value={formData.bleedingCheck || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <FormControl className="text-left">
                <FormLabel id="bleedingCheck">
                  Do you take a blood thinner?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="bloodThinnerCheck"
                  name="bloodThinnerCheck"
                  value={formData.bloodThinnerCheck || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <FormControl className="text-left">
                <FormLabel id="heartProblemCheck">
                  Do you have heart related problems?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="heartProblemCheck"
                  name="heartProblemCheck"
                  value={formData.heartProblemCheck || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <FormControl className="text-left">
                <FormLabel id="highBPCheck">
                  Do you have high blood pressure?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="highBPCheck"
                  name="highBPCheck"
                  value={formData.highBPCheck || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <FormControl className="text-left">
                <FormLabel id="allergiesCheck">
                  Do you have any known allergies?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="allergiesCheck"
                  name="allergiesCheck"
                  value={formData.allergiesCheck || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              {formData.allergiesCheck === "Yes" && (
                <TextField
                  className="col-span-5"
                  id="allergyList"
                  name="allergyList"
                  label="Please list your allergies here separating them with a comma"
                  variant="outlined"
                  onChange={handleChange}
                  required={true}
                />
              )}

              <FormControl className="text-left">
                <FormLabel id="alcoholCheck">
                  Have you consumed any alcoholic beverages within the last 8
                  hours?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="alcoholCheck"
                  name="alcoholCheck"
                  value={formData.alcoholCheck || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <FormControl className="text-left">
                <FormLabel id="foodCheck">
                  Have you consumed any food within the last 2 hours?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="foodCheck"
                  name="foodCheck"
                  value={formData.foodCheck || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <FormControl className="text-left">
                <FormLabel id="anticoagulantsCheck">
                  Have you consumed any anticoagulants (aspirin, ibuprofen,
                  etc.) in the last 24 hours?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="anticoagulantsCheck"
                  name="anticoagulantsCheck"
                  value={formData.anticoagulantsCheck || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <FormControl className="text-left">
                <FormLabel id="pregnantCheck">Are you pregnant?</FormLabel>
                <RadioGroup
                  aria-labelledby="pregnantCheck"
                  name="pregnantCheck"
                  value={formData.pregnantCheck || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <FormControl className="text-left">
                <FormLabel id="skinConditionCheck">
                  Do you have any skin condition such as psoriasis or eczema?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="skinConditionCheck"
                  name="skinConditionCheck"
                  value={formData.skinConditionCheck || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <FormControl className="text-left">
                <FormLabel id="otherConditionsCheck">
                  Do you have any other conditions that might affect the healing
                  of this tattoo/body piercing?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="otherConditionsCheck"
                  name="otherConditionsCheck"
                  value={formData.otherConditionsCheck || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <FormControl className="text-left">
                <FormLabel id="userConfirmationCheck">
                  I have read this release form and confirm that all the
                  information I have given is correct. I understand that this is
                  a release form and I agree to be legally bound by it.
                </FormLabel>
                <RadioGroup
                  aria-labelledby="userConfirmationCheck"
                  name="userConfirmationCheck"
                  value={formData.userConfirmationCheck || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <FormControlLabel
                    value="I confirm"
                    control={<Radio />}
                    label="I confirm"
                  />
                </RadioGroup>
              </FormControl>

              <FormControl className="text-left">
                <FormLabel id="ageConfirmationCheck">
                  I certify that I am over 18 years of age.
                </FormLabel>
                <RadioGroup
                  aria-labelledby="ageConfirmationCheck"
                  name="ageConfirmationCheck"
                  value={formData.ageConfirmationCheck || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <FormControlLabel
                    value="I confirm"
                    control={<Radio required={true} />}
                    label="I confirm"
                  />
                </RadioGroup>
              </FormControl>

              {formData.ageConfirmationCheck === "I confirm" && (
                <div className="text-left gap-3 flex flex-col">
                  <FormLabel>Date of Birth</FormLabel>
                  <div className="grid grid-cols-12 gap-2">
                    <TextField
                      className="col-span-3"
                      id="dateOfBirthDay"
                      name="dateOfBirthDay"
                      value={formData.dateOfBirthDay || ""}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value.length === 2)
                          document.getElementById("dateOfBirthMonth").focus();
                      }}
                      label="Day"
                      variant="outlined"
                      type="number"
                      required={true}
                    />
                    <TextField
                      className="col-span-3"
                      id="dateOfBirthMonth"
                      name="dateOfBirthMonth"
                      value={formData.dateOfBirthMonth || ""}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value.length === 2)
                          document.getElementById("dateOfBirthYear").focus();
                      }}
                      label="Month"
                      variant="outlined"
                      type="number"
                      required={true}
                    />
                    <TextField
                      className="col-span-6"
                      id="dateOfBirthYear"
                      name="dateOfBirthYear"
                      value={formData.dateOfBirthYear || ""}
                      onChange={handleChange}
                      label="Year"
                      variant="outlined"
                      type="number"
                      required={true}
                    />
                  </div>
                </div>
              )}
              <FormControl>
                <TextField
                  id="addressline1"
                  name="addressline1"
                  label="Address"
                  variant="outlined"
                  onChange={handleChange}
                  required={true}
                />
              </FormControl>
              <FormControl>
                <TextField
                  id="towncity"
                  name="towncity"
                  label="Town/City"
                  variant="outlined"
                  onChange={handleChange}
                  required={true}
                />
              </FormControl>
              <FormControl>
                <TextField
                  id="postcode"
                  name="postcode"
                  label="Postcode"
                  variant="outlined"
                  onChange={handleChange}
                  required={true}
                />
              </FormControl>
              <FormControl>
                <TextField
                  id="contactNumber"
                  name="contactNumber"
                  label="Telephone Number"
                  variant="outlined"
                  onChange={handleChange}
                  required={true}
                />
              </FormControl>
              <Typography variant="body2" className="font-bold">
                By submitting this form you confirm that all of the information
                given is complete, true and accurate.
              </Typography>

              <Button variant="contained" color="info" type="submit">
                Submit
              </Button>
              {response && response.success === true ? (
                <Alert severity="success">
                  Successfully completed consent form - Thank you!
                </Alert>
              ) : null}
              {response && response.success === false ? (
                <Alert severity="warning">
                  There was an issue submitting the consent form - Try again or
                  ask for a paper version.
                </Alert>
              ) : null}
            </div>
          </Container>
        </form>
      )}
    </div>
  );
};
