import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export const useTabs = () => {
  const [tabsValue, setTabsValue] = React.useState(0);

  const handleTabsChange = (e, newValue) => {
    setTabsValue(newValue);
  };
  return { a11yProps, TabPanel, handleTabsChange, tabsValue };
};
