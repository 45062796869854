import { Add, PersonAdd } from "@mui/icons-material";
import { Card, CardContent, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import { useArtists } from "../../../hooks/useArtists";
import { AddArtistEntry } from "../../atoms/add-artist-entry/AddArtistEntry";
import { ArtistEntry } from "../../atoms/artist-entry/ArtistEntry";

export const ArtistContainer = () => {
  const { artists, error, isLoading } = useArtists(true);
  const [addArtist, setAddArtist] = useState(false);

  if (isLoading) return "...loading";
  if (error) return "Something happened, try again";

  if (!artists) return null;

  return (
    <div className="flex gap-8 flex-col">
      <div className="text-right">
        <Tooltip title="Add artist">
          <IconButton onClick={() => setAddArtist(!addArtist)}>
            <PersonAdd />
          </IconButton>
        </Tooltip>
      </div>
      {addArtist && (
        <Card key="add-artist-menu" className="md:w-fit">
          <CardContent>
            <div className="flex flex-col">
              <AddArtistEntry />
            </div>
          </CardContent>
        </Card>
      )}
      {artists.map((artist) => (
        <Card key={artist.Id} className="md:w-fit">
          <CardContent>
            <div className="flex flex-col">
              <ArtistEntry artist={artist} />
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};
