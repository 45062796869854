export const PATHS = {
  HOME: "/",
  ARTISTS: "/artists",
  CONSENT_FORM: "/consent-form",
  CONTACT: "/contact",
  BOOKINGS: "/bookings",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  ERROR: "/error",
};

export const BASE_URL = window.location.href.includes("localhost")
  ? "http://localhost:5000"
  : "https://nestofthorns-server.onrender.com";

export const API_PATHS = {
  USERS: "/users",
  ARTISTS: "/artists",
  BOOKING_REQUEST: "/bookingRequest",
  CONTACT_REQUEST: "/contactRequest",
};
