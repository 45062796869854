import { useQuery } from "@tanstack/react-query";
import React from "react";
import { API_PATHS, BASE_URL } from "../config/constants";

export const useArtists = (showAllArtists = false) => {
  const [artists, setArtists] = React.useState(null);

  const { isLoading, error, data, isSuccess } = useQuery({
    queryKey: ["artists"],
    queryFn: () =>
      fetch(`${BASE_URL}${API_PATHS.ARTISTS}`).then((res) => res.json()),
    refetchOnWindowFocus: true,
  });

  React.useEffect(() => {
    if (isSuccess && data.artists) {
      if (showAllArtists) {
        setArtists(data.artists);
        return;
      }
      const mappedArtists = [];
      data.artists.map((artist) => {
        if (!artist.Disabled) mappedArtists.push(artist);
      });
      setArtists(mappedArtists);
    }
  }, [data]);

  return { artists, error, isLoading };
};
