import { Button, Tooltip, Typography } from "@mui/material";

export const UserEntry = ({ user }) => {
  if (!user) return null;

  return (
    <div className="flex flex-col gap-3 text-left">
      <Typography variant="body1">
        Name: <i className="text-slate-500">{user.Name}</i>
      </Typography>
      <Typography variant="body1">
        Email: <i className="text-slate-500">{user.Email}</i>
      </Typography>

      <Tooltip title="Remove user">
        <Button>Remove user</Button>
      </Tooltip>
    </div>
  );
};
