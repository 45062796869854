import { Artist } from "./Artist";

export const Artists = ({ artists }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
      {artists.map((artist) => {
        return <Artist key={artist.Id} {...artist} />;
      })}
    </div>
  );
};
