import axios from "axios";
const BASE_URL = window.location.href.includes("localhost")
  ? "http://localhost:5000"
  : "https://nestofthorns-server.onrender.com";

export default axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
