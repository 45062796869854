import { GoogleMap } from "./Map";

export const Footer = () => {
  return (
    <div className="bottom-0 right-0 left-0 absolute">
      <GoogleMap />
      <div className="background-dark">
        <div className="container mx-auto flex flex-row gap-x-10 items-center text-light h-16">
          <div className="mx-auto">
            <p>
              All rights reserved Nest of Thorns @ {new Date().getFullYear()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
