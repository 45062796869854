import React from "react";
import { useArtists } from "../../hooks/useArtists";
import { ArtistCard } from "./components/ArtistCard";

export const Artists = () => {
  const { artists, error, isLoading } = useArtists();

  if (isLoading) return <p>Loading...</p>;

  if (error) return <p>Oops, an error occured. Please reload.</p>;

  if (artists)
    return (
      <>
        <div className="container flex flex-col mx-auto text-center gap-6 py-4">
          <h1 className="font-light" style={{ fontSize: 30 }}>
            OUR ARTISTS
          </h1>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            {artists.map((artist) => (
              <ArtistCard key={artist.Id} artist={artist} />
            ))}
          </div>
        </div>
      </>
    );

  return null;
};
